import helpers from '@/helpers';

function dateFormat(entity, protocol) {
  if (entity == 'rk') {
    return helpers.dateFormat(protocol.appeal_to_umvd_date);
  } else {
    return helpers.dateFormat(protocol.offence_protocol_object_owner_date);
  }
}

function numberFormat(entity, x, protocol) {
  if (entity == 'rk') {
    return protocol.appeal_to_umvd_number;
  } else {
    return x == 0
      ? protocol.offence_protocol_object_owner_number
      : protocol.offence_protocol_place_owner_number;
  }
}

export default [
  {
    name: 'Дата протокола',
    width: 80,
    width_title: 80,
    width_title_md: 90,
    width_title_lg: 150,

    width_lg: 150,
    width_md: 40,

    // eslint-disable-next-line no-unused-vars
    value: (object, protocol, x) => dateFormat(object.entity, protocol)
  },
  {
    name: 'Номер протокола',
    width: 90,
    width_title: 90,
    width_title_md: 80,

    width_md: 40,
    width_lg: 170,

    value: (object, protocol, x) => numberFormat(object.entity, x, protocol)
  },
  {
    name: 'Статус протокола',
    width: 122,
    width_md: 122,
    width_title: 122,
    width_title_md: 40,
    width_lg: 0,
    // eslint-disable-next-line no-unused-vars
    value: (object = null, protocol, x) => protocol.status_name
  },
  {
    name: 'Сумма выставленного штрафа, руб ',
    width: 120,
    width_md: 122,

    width_title: 120,
    ml: 80,
    value: (object, protocol, x) =>
      x == 0
        ? protocol.amount_of_penalty_object_owner || '0.00'
        : object.amount_of_penalty_place_owner || '0.00'
  },
  {
    name: 'Сумма оплаченного штрафа, руб.',
    width: 120,
    width_title: 120,
    value: (object, protocol, x) =>
      x == 0
        ? protocol.amount_of_penalty_object_owner_paid || '0.00'
        : object.amount_of_penalty_place_owner_paid || '0.00'
  }
];
